<template>
  <div>
    <!-- Title -->

    <div v-if="!route.query.invitation_code">
      <div class="mevcut-form-title mb-5">{{ t('register') }}</div>
      <!-- Phone -->
      <AuthPhoneField
        id="register-phone-input"
        v-model="form.phone"
        error="register-phone"
      />

      <!-- Email -->
      <AuthTextField
        v-model="form.email"
        :label="t('email')"
        placeholder="example@email.com"
        type="email"
        error="register-email"
      />

      <!-- Password -->
      <AuthTextField
        v-model="form.password"
        :label="t('password')"
        placeholder="********"
        type="password"
        error="register-password"
      />

      <!-- Password Confirmation -->
      <AuthTextField
        v-model="form.passwordConfirmation"
        :label="t('password_confirmation')"
        placeholder="********"
        type="password"
        error="register-password_confirmation"
      />

      <!-- Privecy Policy -->
      <MevcutCheckbox v-model="form.terms" :label="false" class="mt-5">
        <div class="mr-2">
          {{ t('i_ve_read') }}
          <MevcutLink
            :with-branch-id-query="false"
            to="/terms"
            target="_blank"
            class="text-sm text-nav_color font-medium hover:underline"
            :aria-label="t('aria_page_label', { page: t('terms_of_use') })"
          >
            {{ t('terms_of_use') }}
          </MevcutLink>
          {{ t('and_agree_it') }}
        </div>
      </MevcutCheckbox>

      <!-- Submit -->
      <MevcutResponseError
        class="my-2"
        api-key="register"
        :except="['register-email', 'register-phone', 'register-password']"
      />
      <MevcutSubmitButton
        class="my-2"
        :loading="form.loading"
        :enable-click-disable-mode="true"
        :disabled="!form.terms"
        @submit="registerHandler"
      />
    </div>

    <div v-else class="items-center mt-8">
      <div class="text-center mb-5">
        {{ t('referral_code_message') }}
      </div>
      <div class="flex justify-center">
        <a
          v-if="settings.apple_store"
          class="mx-1"
          :href="settings.apple_store"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon :size="35">
            <IconsAppleStore />
          </v-icon>
        </a>
        <a
          v-if="settings.google_play"
          class="mx-1"
          :href="settings.google_play"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon :size="35">
            <IconsGooglePlay />
          </v-icon>
        </a>
        <a
          v-if="settings.direct_download"
          :href="settings.direct_download"
          target="_blank"
          rel="noopener noreferrer"
          class="bg-gray-500 hover:bg-gray-700 text-white font-bold p-1 mx-1 rounded"
        >
          {{ t('download_directly') }}
        </a>
      </div>
      <MevcutLink to="/auth/login" class="w-full ">
        <div class="text-center text-sm mt-6">
          {{ t('continue_without_referral') }}
        </div>
      </MevcutLink>
    </div>
  </div>
</template>

<script setup lang="ts">
definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/'
  }
})
const { settings } = useSettings()
const route = useRoute()
const { form, registerHandler } = useRegister()
const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/auth/register',
    t('register'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('register'),
  link: [useSeoCanonical(url + '/auth/register')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('register'), url + '/auth/register')
    ])
  ]
})
</script>
